import './App.css';

function App() {
    return (
        <div className="App">
            <div className="container d-flex align-items-center h-100">
                <div className="row">
                    <div className="text-center col-12">
                        <h1 className="text-uppercase"><strong>Tent Hill Homestead is about to get real!</strong></h1>
                    </div>
                    <div className="buffer col-12"></div>
                    <section className="text-center col-12">
                        <a href="mailto:info@tenthillhomestead.au" className="btn btn-primary btn-xl">To find out
                            when, click..</a>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default App;
